/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface Document
 */
export interface Document {
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    'accountId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    'currentFileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    'originalFileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    'checkCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    'entityType'?: DocumentEntityTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    'documentType'?: DocumentDocumentTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    'entityId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    'gcsBucket'?: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    'contentType'?: string;
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    'version'?: number;
}

export const DocumentEntityTypeEnum = {
    PurchaseOrder: 'PURCHASE_ORDER',
    Invoice: 'INVOICE',
    ShipmentPlan: 'SHIPMENT_PLAN',
    None: 'NONE'
} as const;

export type DocumentEntityTypeEnum = typeof DocumentEntityTypeEnum[keyof typeof DocumentEntityTypeEnum];
export const DocumentDocumentTypeEnum = {
    PurchaseOrder: 'PURCHASE_ORDER',
    Invoice: 'INVOICE',
    ShipmentPlan: 'SHIPMENT_PLAN',
    Other: 'OTHER'
} as const;

export type DocumentDocumentTypeEnum = typeof DocumentDocumentTypeEnum[keyof typeof DocumentDocumentTypeEnum];

/**
 * 
 * @export
 * @interface DocumentUpdateDto
 */
export interface DocumentUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof DocumentUpdateDto
     */
    'entityType'?: DocumentUpdateDtoEntityTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DocumentUpdateDto
     */
    'documentType'?: DocumentUpdateDtoDocumentTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof DocumentUpdateDto
     */
    'entityId'?: number;
}

export const DocumentUpdateDtoEntityTypeEnum = {
    PurchaseOrder: 'PURCHASE_ORDER',
    Invoice: 'INVOICE',
    ShipmentPlan: 'SHIPMENT_PLAN',
    None: 'NONE'
} as const;

export type DocumentUpdateDtoEntityTypeEnum = typeof DocumentUpdateDtoEntityTypeEnum[keyof typeof DocumentUpdateDtoEntityTypeEnum];
export const DocumentUpdateDtoDocumentTypeEnum = {
    PurchaseOrder: 'PURCHASE_ORDER',
    Invoice: 'INVOICE',
    ShipmentPlan: 'SHIPMENT_PLAN',
    Other: 'OTHER'
} as const;

export type DocumentUpdateDtoDocumentTypeEnum = typeof DocumentUpdateDtoDocumentTypeEnum[keyof typeof DocumentUpdateDtoDocumentTypeEnum];

/**
 * 
 * @export
 * @interface ErrorDetails
 */
export interface ErrorDetails {
    /**
     * 
     * @type {string}
     * @memberof ErrorDetails
     */
    'timestamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorDetails
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorDetails
     */
    'details'?: string;
}
/**
 * 
 * @export
 * @interface PageDocument
 */
export interface PageDocument {
    /**
     * 
     * @type {number}
     * @memberof PageDocument
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDocument
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDocument
     */
    'size'?: number;
    /**
     * 
     * @type {Array<Document>}
     * @memberof PageDocument
     */
    'content'?: Array<Document>;
    /**
     * 
     * @type {number}
     * @memberof PageDocument
     */
    'number'?: number;
    /**
     * 
     * @type {Array<SortObject>}
     * @memberof PageDocument
     */
    'sort'?: Array<SortObject>;
    /**
     * 
     * @type {number}
     * @memberof PageDocument
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageDocument
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageDocument
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageDocument
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageDocument
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface Pageable
 */
export interface Pageable {
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    'size'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof Pageable
     */
    'sort'?: Array<string>;
}
/**
 * 
 * @export
 * @interface PageableObject
 */
export interface PageableObject {
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    'offset'?: number;
    /**
     * 
     * @type {Array<SortObject>}
     * @memberof PageableObject
     */
    'sort'?: Array<SortObject>;
    /**
     * 
     * @type {boolean}
     * @memberof PageableObject
     */
    'paged'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageableObject
     */
    'unpaged'?: boolean;
}
/**
 * 
 * @export
 * @interface ReplaceGcsFileResourceFromMultiPartFormForUserRequest
 */
export interface ReplaceGcsFileResourceFromMultiPartFormForUserRequest {
    /**
     * 
     * @type {File}
     * @memberof ReplaceGcsFileResourceFromMultiPartFormForUserRequest
     */
    'file': File;
}
/**
 * 
 * @export
 * @interface SendEmailDto
 */
export interface SendEmailDto {
    /**
     * 
     * @type {string}
     * @memberof SendEmailDto
     */
    'fromEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof SendEmailDto
     */
    'fromName'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SendEmailDto
     */
    'toAddresses'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SendEmailDto
     */
    'subject'?: string;
    /**
     * 
     * @type {string}
     * @memberof SendEmailDto
     */
    'textContent'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SendEmailDto
     */
    'ccAddresses'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SendEmailDto
     */
    'bccAddresses'?: Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof SendEmailDto
     */
    'documentIds'?: Array<number>;
}
/**
 * 
 * @export
 * @interface SortObject
 */
export interface SortObject {
    /**
     * 
     * @type {string}
     * @memberof SortObject
     */
    'direction'?: string;
    /**
     * 
     * @type {string}
     * @memberof SortObject
     */
    'nullHandling'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SortObject
     */
    'ascending'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SortObject
     */
    'property'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SortObject
     */
    'ignoreCase'?: boolean;
}

/**
 * DocumentAdminControllerApi - axios parameter creator
 * @export
 */
export const DocumentAdminControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importDocumentsFromInvoiceDocuments: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/documents/import-invoices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncAllDocuments: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/documents/sync/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncAllDocumentsForUser: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('syncAllDocumentsForUser', 'userId', userId)
            const localVarPath = `/admin/documents/{userId}/sync`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentAdminControllerApi - functional programming interface
 * @export
 */
export const DocumentAdminControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentAdminControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importDocumentsFromInvoiceDocuments(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importDocumentsFromInvoiceDocuments(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentAdminControllerApi.importDocumentsFromInvoiceDocuments']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncAllDocuments(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncAllDocuments(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentAdminControllerApi.syncAllDocuments']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncAllDocumentsForUser(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncAllDocumentsForUser(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentAdminControllerApi.syncAllDocumentsForUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DocumentAdminControllerApi - factory interface
 * @export
 */
export const DocumentAdminControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentAdminControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importDocumentsFromInvoiceDocuments(options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.importDocumentsFromInvoiceDocuments(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncAllDocuments(options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.syncAllDocuments(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncAllDocumentsForUser(userId: number, options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.syncAllDocumentsForUser(userId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentAdminControllerApi - object-oriented interface
 * @export
 * @class DocumentAdminControllerApi
 * @extends {BaseAPI}
 */
export class DocumentAdminControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentAdminControllerApi
     */
    public importDocumentsFromInvoiceDocuments(options?: RawAxiosRequestConfig) {
        return DocumentAdminControllerApiFp(this.configuration).importDocumentsFromInvoiceDocuments(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentAdminControllerApi
     */
    public syncAllDocuments(options?: RawAxiosRequestConfig) {
        return DocumentAdminControllerApiFp(this.configuration).syncAllDocuments(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentAdminControllerApi
     */
    public syncAllDocumentsForUser(userId: number, options?: RawAxiosRequestConfig) {
        return DocumentAdminControllerApiFp(this.configuration).syncAllDocumentsForUser(userId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DocumentControllerApi - axios parameter creator
 * @export
 */
export const DocumentControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} userId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGcsFileResource: async (userId: number, documentId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteGcsFileResource', 'userId', userId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('deleteGcsFileResource', 'documentId', documentId)
            const localVarPath = `/documents/{userId}/{documentId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentControllerTest: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('documentControllerTest', 'userId', userId)
            const localVarPath = `/documents/{userId}/test`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDocumentByIdForUser: async (userId: number, documentId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('downloadDocumentByIdForUser', 'userId', userId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('downloadDocumentByIdForUser', 'documentId', documentId)
            const localVarPath = `/documents/{userId}/{documentId}/download`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAllDocumentsForUser: async (pageable: Pageable, userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('fetchAllDocumentsForUser', 'pageable', pageable)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('fetchAllDocumentsForUser', 'userId', userId)
            const localVarPath = `/documents/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {number} userId 
         * @param {FetchAllDocumentsForUserByEntityIdEntityTypeEnum} entityType 
         * @param {number} entityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAllDocumentsForUserByEntityId: async (pageable: Pageable, userId: number, entityType: FetchAllDocumentsForUserByEntityIdEntityTypeEnum, entityId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('fetchAllDocumentsForUserByEntityId', 'pageable', pageable)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('fetchAllDocumentsForUserByEntityId', 'userId', userId)
            // verify required parameter 'entityType' is not null or undefined
            assertParamExists('fetchAllDocumentsForUserByEntityId', 'entityType', entityType)
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('fetchAllDocumentsForUserByEntityId', 'entityId', entityId)
            const localVarPath = `/documents/{userId}/entity/{entityType}/{entityId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"entityType"}}`, encodeURIComponent(String(entityType)))
                .replace(`{${"entityId"}}`, encodeURIComponent(String(entityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {number} userId 
         * @param {FetchAllDocumentsForUserByEntityTypeEntityTypeEnum} entityType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAllDocumentsForUserByEntityType: async (pageable: Pageable, userId: number, entityType: FetchAllDocumentsForUserByEntityTypeEntityTypeEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('fetchAllDocumentsForUserByEntityType', 'pageable', pageable)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('fetchAllDocumentsForUserByEntityType', 'userId', userId)
            // verify required parameter 'entityType' is not null or undefined
            assertParamExists('fetchAllDocumentsForUserByEntityType', 'entityType', entityType)
            const localVarPath = `/documents/{userId}/entity-type/{entityType}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"entityType"}}`, encodeURIComponent(String(entityType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchSvDocumentByIdForUser: async (userId: number, documentId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('fetchSvDocumentByIdForUser', 'userId', userId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('fetchSvDocumentByIdForUser', 'documentId', documentId)
            const localVarPath = `/documents/{userId}/{documentId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentTypes: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getDocumentTypes', 'userId', userId)
            const localVarPath = `/documents/{userId}/document-types`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityTypes: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getEntityTypes', 'userId', userId)
            const localVarPath = `/documents/{userId}/entity-types`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} id 
         * @param {{ [key: string]: string; }} body 
         * @param {ReplaceGcsFileResourceFromMultiPartFormForUserRequest} [replaceGcsFileResourceFromMultiPartFormForUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceGcsFileResourceFromMultiPartFormForUser: async (userId: number, id: number, body: { [key: string]: string; }, replaceGcsFileResourceFromMultiPartFormForUserRequest?: ReplaceGcsFileResourceFromMultiPartFormForUserRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('replaceGcsFileResourceFromMultiPartFormForUser', 'userId', userId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('replaceGcsFileResourceFromMultiPartFormForUser', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('replaceGcsFileResourceFromMultiPartFormForUser', 'body', body)
            const localVarPath = `/documents/{userId}/{id}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (body !== undefined) {
                for (const [key, value] of Object.entries(body)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(replaceGcsFileResourceFromMultiPartFormForUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {SendEmailDto} sendEmailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmailWithAttachments: async (userId: number, sendEmailDto: SendEmailDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('sendEmailWithAttachments', 'userId', userId)
            // verify required parameter 'sendEmailDto' is not null or undefined
            assertParamExists('sendEmailWithAttachments', 'sendEmailDto', sendEmailDto)
            const localVarPath = `/documents/{userId}/send-email-with-attachments`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendEmailDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} id 
         * @param {DocumentUpdateDto} documentUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExistingDocumentForUser: async (userId: number, id: number, documentUpdateDto: DocumentUpdateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateExistingDocumentForUser', 'userId', userId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateExistingDocumentForUser', 'id', id)
            // verify required parameter 'documentUpdateDto' is not null or undefined
            assertParamExists('updateExistingDocumentForUser', 'documentUpdateDto', documentUpdateDto)
            const localVarPath = `/documents/{userId}/{id}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {{ [key: string]: string; }} body 
         * @param {ReplaceGcsFileResourceFromMultiPartFormForUserRequest} [replaceGcsFileResourceFromMultiPartFormForUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        writeGcsFileResourceFromMultiPartForm: async (userId: number, body: { [key: string]: string; }, replaceGcsFileResourceFromMultiPartFormForUserRequest?: ReplaceGcsFileResourceFromMultiPartFormForUserRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('writeGcsFileResourceFromMultiPartForm', 'userId', userId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('writeGcsFileResourceFromMultiPartForm', 'body', body)
            const localVarPath = `/documents/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (body !== undefined) {
                for (const [key, value] of Object.entries(body)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(replaceGcsFileResourceFromMultiPartFormForUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentControllerApi - functional programming interface
 * @export
 */
export const DocumentControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteGcsFileResource(userId: number, documentId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteGcsFileResource(userId, documentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentControllerApi.deleteGcsFileResource']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentControllerTest(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentControllerTest(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentControllerApi.documentControllerTest']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadDocumentByIdForUser(userId: number, documentId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadDocumentByIdForUser(userId, documentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentControllerApi.downloadDocumentByIdForUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchAllDocumentsForUser(pageable: Pageable, userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDocument>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchAllDocumentsForUser(pageable, userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentControllerApi.fetchAllDocumentsForUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {number} userId 
         * @param {FetchAllDocumentsForUserByEntityIdEntityTypeEnum} entityType 
         * @param {number} entityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchAllDocumentsForUserByEntityId(pageable: Pageable, userId: number, entityType: FetchAllDocumentsForUserByEntityIdEntityTypeEnum, entityId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDocument>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchAllDocumentsForUserByEntityId(pageable, userId, entityType, entityId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentControllerApi.fetchAllDocumentsForUserByEntityId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {number} userId 
         * @param {FetchAllDocumentsForUserByEntityTypeEntityTypeEnum} entityType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchAllDocumentsForUserByEntityType(pageable: Pageable, userId: number, entityType: FetchAllDocumentsForUserByEntityTypeEntityTypeEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDocument>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchAllDocumentsForUserByEntityType(pageable, userId, entityType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentControllerApi.fetchAllDocumentsForUserByEntityType']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchSvDocumentByIdForUser(userId: number, documentId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Document>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchSvDocumentByIdForUser(userId, documentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentControllerApi.fetchSvDocumentByIdForUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentTypes(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentTypes(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentControllerApi.getDocumentTypes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEntityTypes(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEntityTypes(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentControllerApi.getEntityTypes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} id 
         * @param {{ [key: string]: string; }} body 
         * @param {ReplaceGcsFileResourceFromMultiPartFormForUserRequest} [replaceGcsFileResourceFromMultiPartFormForUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceGcsFileResourceFromMultiPartFormForUser(userId: number, id: number, body: { [key: string]: string; }, replaceGcsFileResourceFromMultiPartFormForUserRequest?: ReplaceGcsFileResourceFromMultiPartFormForUserRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Document>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.replaceGcsFileResourceFromMultiPartFormForUser(userId, id, body, replaceGcsFileResourceFromMultiPartFormForUserRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentControllerApi.replaceGcsFileResourceFromMultiPartFormForUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {SendEmailDto} sendEmailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendEmailWithAttachments(userId: number, sendEmailDto: SendEmailDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendEmailWithAttachments(userId, sendEmailDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentControllerApi.sendEmailWithAttachments']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} id 
         * @param {DocumentUpdateDto} documentUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateExistingDocumentForUser(userId: number, id: number, documentUpdateDto: DocumentUpdateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Document>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateExistingDocumentForUser(userId, id, documentUpdateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentControllerApi.updateExistingDocumentForUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {{ [key: string]: string; }} body 
         * @param {ReplaceGcsFileResourceFromMultiPartFormForUserRequest} [replaceGcsFileResourceFromMultiPartFormForUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async writeGcsFileResourceFromMultiPartForm(userId: number, body: { [key: string]: string; }, replaceGcsFileResourceFromMultiPartFormForUserRequest?: ReplaceGcsFileResourceFromMultiPartFormForUserRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Document>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.writeGcsFileResourceFromMultiPartForm(userId, body, replaceGcsFileResourceFromMultiPartFormForUserRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentControllerApi.writeGcsFileResourceFromMultiPartForm']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DocumentControllerApi - factory interface
 * @export
 */
export const DocumentControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGcsFileResource(userId: number, documentId: string, options?: any): AxiosPromise<string> {
            return localVarFp.deleteGcsFileResource(userId, documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentControllerTest(userId: number, options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.documentControllerTest(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDocumentByIdForUser(userId: number, documentId: string, options?: any): AxiosPromise<File> {
            return localVarFp.downloadDocumentByIdForUser(userId, documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAllDocumentsForUser(pageable: Pageable, userId: number, options?: any): AxiosPromise<PageDocument> {
            return localVarFp.fetchAllDocumentsForUser(pageable, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {number} userId 
         * @param {FetchAllDocumentsForUserByEntityIdEntityTypeEnum} entityType 
         * @param {number} entityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAllDocumentsForUserByEntityId(pageable: Pageable, userId: number, entityType: FetchAllDocumentsForUserByEntityIdEntityTypeEnum, entityId: number, options?: any): AxiosPromise<PageDocument> {
            return localVarFp.fetchAllDocumentsForUserByEntityId(pageable, userId, entityType, entityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {number} userId 
         * @param {FetchAllDocumentsForUserByEntityTypeEntityTypeEnum} entityType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAllDocumentsForUserByEntityType(pageable: Pageable, userId: number, entityType: FetchAllDocumentsForUserByEntityTypeEntityTypeEnum, options?: any): AxiosPromise<PageDocument> {
            return localVarFp.fetchAllDocumentsForUserByEntityType(pageable, userId, entityType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchSvDocumentByIdForUser(userId: number, documentId: number, options?: any): AxiosPromise<Document> {
            return localVarFp.fetchSvDocumentByIdForUser(userId, documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentTypes(userId: number, options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.getDocumentTypes(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityTypes(userId: number, options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.getEntityTypes(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} id 
         * @param {{ [key: string]: string; }} body 
         * @param {ReplaceGcsFileResourceFromMultiPartFormForUserRequest} [replaceGcsFileResourceFromMultiPartFormForUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceGcsFileResourceFromMultiPartFormForUser(userId: number, id: number, body: { [key: string]: string; }, replaceGcsFileResourceFromMultiPartFormForUserRequest?: ReplaceGcsFileResourceFromMultiPartFormForUserRequest, options?: any): AxiosPromise<Document> {
            return localVarFp.replaceGcsFileResourceFromMultiPartFormForUser(userId, id, body, replaceGcsFileResourceFromMultiPartFormForUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {SendEmailDto} sendEmailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmailWithAttachments(userId: number, sendEmailDto: SendEmailDto, options?: any): AxiosPromise<string> {
            return localVarFp.sendEmailWithAttachments(userId, sendEmailDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} id 
         * @param {DocumentUpdateDto} documentUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExistingDocumentForUser(userId: number, id: number, documentUpdateDto: DocumentUpdateDto, options?: any): AxiosPromise<Document> {
            return localVarFp.updateExistingDocumentForUser(userId, id, documentUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {{ [key: string]: string; }} body 
         * @param {ReplaceGcsFileResourceFromMultiPartFormForUserRequest} [replaceGcsFileResourceFromMultiPartFormForUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        writeGcsFileResourceFromMultiPartForm(userId: number, body: { [key: string]: string; }, replaceGcsFileResourceFromMultiPartFormForUserRequest?: ReplaceGcsFileResourceFromMultiPartFormForUserRequest, options?: any): AxiosPromise<Document> {
            return localVarFp.writeGcsFileResourceFromMultiPartForm(userId, body, replaceGcsFileResourceFromMultiPartFormForUserRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentControllerApi - object-oriented interface
 * @export
 * @class DocumentControllerApi
 * @extends {BaseAPI}
 */
export class DocumentControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} userId 
     * @param {string} documentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentControllerApi
     */
    public deleteGcsFileResource(userId: number, documentId: string, options?: RawAxiosRequestConfig) {
        return DocumentControllerApiFp(this.configuration).deleteGcsFileResource(userId, documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentControllerApi
     */
    public documentControllerTest(userId: number, options?: RawAxiosRequestConfig) {
        return DocumentControllerApiFp(this.configuration).documentControllerTest(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {string} documentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentControllerApi
     */
    public downloadDocumentByIdForUser(userId: number, documentId: string, options?: RawAxiosRequestConfig) {
        return DocumentControllerApiFp(this.configuration).downloadDocumentByIdForUser(userId, documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pageable} pageable 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentControllerApi
     */
    public fetchAllDocumentsForUser(pageable: Pageable, userId: number, options?: RawAxiosRequestConfig) {
        return DocumentControllerApiFp(this.configuration).fetchAllDocumentsForUser(pageable, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pageable} pageable 
     * @param {number} userId 
     * @param {FetchAllDocumentsForUserByEntityIdEntityTypeEnum} entityType 
     * @param {number} entityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentControllerApi
     */
    public fetchAllDocumentsForUserByEntityId(pageable: Pageable, userId: number, entityType: FetchAllDocumentsForUserByEntityIdEntityTypeEnum, entityId: number, options?: RawAxiosRequestConfig) {
        return DocumentControllerApiFp(this.configuration).fetchAllDocumentsForUserByEntityId(pageable, userId, entityType, entityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pageable} pageable 
     * @param {number} userId 
     * @param {FetchAllDocumentsForUserByEntityTypeEntityTypeEnum} entityType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentControllerApi
     */
    public fetchAllDocumentsForUserByEntityType(pageable: Pageable, userId: number, entityType: FetchAllDocumentsForUserByEntityTypeEntityTypeEnum, options?: RawAxiosRequestConfig) {
        return DocumentControllerApiFp(this.configuration).fetchAllDocumentsForUserByEntityType(pageable, userId, entityType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {number} documentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentControllerApi
     */
    public fetchSvDocumentByIdForUser(userId: number, documentId: number, options?: RawAxiosRequestConfig) {
        return DocumentControllerApiFp(this.configuration).fetchSvDocumentByIdForUser(userId, documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentControllerApi
     */
    public getDocumentTypes(userId: number, options?: RawAxiosRequestConfig) {
        return DocumentControllerApiFp(this.configuration).getDocumentTypes(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentControllerApi
     */
    public getEntityTypes(userId: number, options?: RawAxiosRequestConfig) {
        return DocumentControllerApiFp(this.configuration).getEntityTypes(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {number} id 
     * @param {{ [key: string]: string; }} body 
     * @param {ReplaceGcsFileResourceFromMultiPartFormForUserRequest} [replaceGcsFileResourceFromMultiPartFormForUserRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentControllerApi
     */
    public replaceGcsFileResourceFromMultiPartFormForUser(userId: number, id: number, body: { [key: string]: string; }, replaceGcsFileResourceFromMultiPartFormForUserRequest?: ReplaceGcsFileResourceFromMultiPartFormForUserRequest, options?: RawAxiosRequestConfig) {
        return DocumentControllerApiFp(this.configuration).replaceGcsFileResourceFromMultiPartFormForUser(userId, id, body, replaceGcsFileResourceFromMultiPartFormForUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {SendEmailDto} sendEmailDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentControllerApi
     */
    public sendEmailWithAttachments(userId: number, sendEmailDto: SendEmailDto, options?: RawAxiosRequestConfig) {
        return DocumentControllerApiFp(this.configuration).sendEmailWithAttachments(userId, sendEmailDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {number} id 
     * @param {DocumentUpdateDto} documentUpdateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentControllerApi
     */
    public updateExistingDocumentForUser(userId: number, id: number, documentUpdateDto: DocumentUpdateDto, options?: RawAxiosRequestConfig) {
        return DocumentControllerApiFp(this.configuration).updateExistingDocumentForUser(userId, id, documentUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {{ [key: string]: string; }} body 
     * @param {ReplaceGcsFileResourceFromMultiPartFormForUserRequest} [replaceGcsFileResourceFromMultiPartFormForUserRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentControllerApi
     */
    public writeGcsFileResourceFromMultiPartForm(userId: number, body: { [key: string]: string; }, replaceGcsFileResourceFromMultiPartFormForUserRequest?: ReplaceGcsFileResourceFromMultiPartFormForUserRequest, options?: RawAxiosRequestConfig) {
        return DocumentControllerApiFp(this.configuration).writeGcsFileResourceFromMultiPartForm(userId, body, replaceGcsFileResourceFromMultiPartFormForUserRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const FetchAllDocumentsForUserByEntityIdEntityTypeEnum = {
    PurchaseOrder: 'PURCHASE_ORDER',
    Invoice: 'INVOICE',
    ShipmentPlan: 'SHIPMENT_PLAN',
    None: 'NONE'
} as const;
export type FetchAllDocumentsForUserByEntityIdEntityTypeEnum = typeof FetchAllDocumentsForUserByEntityIdEntityTypeEnum[keyof typeof FetchAllDocumentsForUserByEntityIdEntityTypeEnum];
/**
 * @export
 */
export const FetchAllDocumentsForUserByEntityTypeEntityTypeEnum = {
    PurchaseOrder: 'PURCHASE_ORDER',
    Invoice: 'INVOICE',
    ShipmentPlan: 'SHIPMENT_PLAN',
    None: 'NONE'
} as const;
export type FetchAllDocumentsForUserByEntityTypeEntityTypeEnum = typeof FetchAllDocumentsForUserByEntityTypeEntityTypeEnum[keyof typeof FetchAllDocumentsForUserByEntityTypeEntityTypeEnum];


