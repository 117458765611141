import type { RouteConfig } from 'vue-router'

import { ensureToken } from '@/router/guards/ensureToken'

export default [
  {
    path: '/orders-shipments',
    component: () => import(/* webpackChunkName: "orders-shipments-cartons-views" */ './views/OrdersShipmentCartons.vue'),
    meta: {
      orders: true,
      management: true
    },
    beforeEnter: ensureToken,
    children: [
      {
        path: '',
        name: 'orders',
        component: () => import(/* webpackChunkName: "orders-shipments-cartons-views" */ './modules/orders/views/Orders.vue')
      },
      {
        path: 'shipment-plans',
        name: 'shipmentPlans',
        props: true,
        component: () => import(/* webpackChunkName: "orders-shipments-cartons-views" */ './modules/shipment-plans/views/ShipmentPlans.vue')
      },
      {
        path: 'cartons',
        name: 'cartons',
        props: true,
        component: () => import(/* webpackChunkName: "orders-shipments-cartons-views" */ './modules/cartons/views/Cartons.vue')
      }
      // {
      //   path: 'shipment-plans/:shipmentId?',
      //   name: 'shipmentPlans',
      //   props: true,
      //   component: () => import(/* webpackChunkName: "shipment-plans-views" */ './modules/shipment-plans/views/ShipmentPlans.vue')
      // }
    ]
  },
  // {
  //   path: '/orders',
  //   component: () => import(/* webpackChunkName: "orders-shipments-views" */ './modules/orders/views/sampleOrder.vue'),
  //   beforeEnter: ensureToken,
  //   meta: {
  //     orders: true,
  //     management: true
  //   }
  // },
  {
    path: '/shipment-plan/:id/send',
    name: 'shipmentPlansSend',
    component: () => import(/* webpackChunkName: "shipment-plans-views" */ './modules/shipment-plans/views/ShipmentPlanSend.vue'),
    beforeEnter: ensureToken,
    props: true,
    meta: {
      orders: true,
      management: true
    }
  }
] as Array<RouteConfig>
