/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AccountCode
 */
export interface AccountCode {
    /**
     * 
     * @type {number}
     * @memberof AccountCode
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AccountCode
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountCode
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountCode
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountCode
     */
    'accountCodeId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccountCode
     */
    'isSystemDefault'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AccountCode
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof AccountCode
     */
    'accountId'?: number;
    /**
     * 
     * @type {string}
     * @memberof AccountCode
     */
    'accountCodeType'?: AccountCodeAccountCodeTypeEnum;
}

export const AccountCodeAccountCodeTypeEnum = {
    Bank: 'BANK',
    Current: 'CURRENT',
    Currliab: 'CURRLIAB',
    Depreciatn: 'DEPRECIATN',
    Directcosts: 'DIRECTCOSTS',
    Equity: 'EQUITY',
    Expense: 'EXPENSE',
    Fixed: 'FIXED',
    Inventory: 'INVENTORY',
    Liability: 'LIABILITY',
    Noncurrent: 'NONCURRENT',
    Otherincome: 'OTHERINCOME',
    Overheads: 'OVERHEADS',
    Prepayment: 'PREPAYMENT',
    Revenue: 'REVENUE',
    Sales: 'SALES',
    Termliab: 'TERMLIAB',
    Paygliability: 'PAYGLIABILITY',
    Payg: 'PAYG',
    Superannuationexpense: 'SUPERANNUATIONEXPENSE',
    Superannuationliability: 'SUPERANNUATIONLIABILITY',
    Wagesexpense: 'WAGESEXPENSE'
} as const;

export type AccountCodeAccountCodeTypeEnum = typeof AccountCodeAccountCodeTypeEnum[keyof typeof AccountCodeAccountCodeTypeEnum];

/**
 * 
 * @export
 * @interface CreateInvoiceFromPoOrSupplierDto
 */
export interface CreateInvoiceFromPoOrSupplierDto {
    /**
     * 
     * @type {number}
     * @memberof CreateInvoiceFromPoOrSupplierDto
     */
    'purchaseOrderId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateInvoiceFromPoOrSupplierDto
     */
    'shipmentPlanId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateInvoiceFromPoOrSupplierDto
     */
    'documentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateInvoiceFromPoOrSupplierDto
     */
    'invoiceImportType'?: CreateInvoiceFromPoOrSupplierDtoInvoiceImportTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof CreateInvoiceFromPoOrSupplierDto
     */
    'shipmentTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateInvoiceFromPoOrSupplierDto
     */
    'customsTotal'?: number;
}

export const CreateInvoiceFromPoOrSupplierDtoInvoiceImportTypeEnum = {
    ShippingOnly: 'SHIPPING_ONLY',
    ShippingAndCustoms: 'SHIPPING_AND_CUSTOMS',
    CustomsOnly: 'CUSTOMS_ONLY'
} as const;

export type CreateInvoiceFromPoOrSupplierDtoInvoiceImportTypeEnum = typeof CreateInvoiceFromPoOrSupplierDtoInvoiceImportTypeEnum[keyof typeof CreateInvoiceFromPoOrSupplierDtoInvoiceImportTypeEnum];

/**
 * 
 * @export
 * @interface Currency
 */
export interface Currency {
    /**
     * 
     * @type {number}
     * @memberof Currency
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Currency
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof Currency
     */
    'currencyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Currency
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof Currency
     */
    'currencySymbol'?: string;
    /**
     * 
     * @type {number}
     * @memberof Currency
     */
    'sortField'?: number;
    /**
     * 
     * @type {number}
     * @memberof Currency
     */
    'precision'?: number;
}
/**
 * 
 * @export
 * @interface ImportGoogleSheetDto
 */
export interface ImportGoogleSheetDto {
    /**
     * 
     * @type {string}
     * @memberof ImportGoogleSheetDto
     */
    'googleSheetsUrl'?: string;
}
/**
 * 
 * @export
 * @interface Invoice
 */
export interface Invoice {
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    'invoiceNumber'?: string;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    'unixDate'?: number;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    'status'?: InvoiceStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    'billToCompany'?: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    'payReferenceNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    'attention'?: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    'trackingNumber'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Invoice
     */
    'reconciled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    'costType'?: InvoiceCostTypeEnum;
    /**
     * 
     * @type {Array<InvoiceLine>}
     * @memberof Invoice
     */
    'invoiceLines'?: Array<InvoiceLine>;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    'accountId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    'xeroId'?: string;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    'purchaseOrderId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    'shipmentPlanId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    'documentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    'purchaseOrderNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    'shipmentPlanName'?: string;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    'invoiceTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    'invoiceLineTotal'?: number;
    /**
     * 
     * @type {Supplier}
     * @memberof Invoice
     */
    'supplier'?: Supplier;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    'invoicePaymentId'?: number;
}

export const InvoiceStatusEnum = {
    Review: 'REVIEW',
    Working: 'WORKING',
    Open: 'OPEN',
    Closed: 'CLOSED'
} as const;

export type InvoiceStatusEnum = typeof InvoiceStatusEnum[keyof typeof InvoiceStatusEnum];
export const InvoiceCostTypeEnum = {
    Shipping: 'SHIPPING',
    FactoryInvoice: 'FACTORY_INVOICE',
    CustomsAndDuties: 'CUSTOMS_AND_DUTIES',
    Inspections: 'INSPECTIONS',
    LocalFreight: 'LOCAL_FREIGHT',
    Photography: 'PHOTOGRAPHY',
    DesignerFees: 'DESIGNER_FEES',
    ProductSamples: 'PRODUCT_SAMPLES',
    ProductTesting: 'PRODUCT_TESTING',
    Storage: 'STORAGE',
    Insurance: 'INSURANCE',
    BankFees: 'BANK_FEES',
    Other: 'OTHER'
} as const;

export type InvoiceCostTypeEnum = typeof InvoiceCostTypeEnum[keyof typeof InvoiceCostTypeEnum];

/**
 * 
 * @export
 * @interface InvoiceBasicDto
 */
export interface InvoiceBasicDto {
    /**
     * 
     * @type {number}
     * @memberof InvoiceBasicDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceBasicDto
     */
    'invoiceNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceBasicDto
     */
    'date'?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceBasicDto
     */
    'unixDate'?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceBasicDto
     */
    'status'?: InvoiceBasicDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof InvoiceBasicDto
     */
    'billToCompany'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceBasicDto
     */
    'payReferenceNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceBasicDto
     */
    'attention'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceBasicDto
     */
    'trackingNumber'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceBasicDto
     */
    'reconciled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InvoiceBasicDto
     */
    'costType'?: InvoiceBasicDtoCostTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof InvoiceBasicDto
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceBasicDto
     */
    'accountId'?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceBasicDto
     */
    'xeroId'?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceBasicDto
     */
    'invoiceTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceBasicDto
     */
    'invoiceLineTotal'?: number;
    /**
     * 
     * @type {Supplier}
     * @memberof InvoiceBasicDto
     */
    'supplier'?: Supplier;
    /**
     * 
     * @type {string}
     * @memberof InvoiceBasicDto
     */
    'notes'?: string;
}

export const InvoiceBasicDtoStatusEnum = {
    Review: 'REVIEW',
    Working: 'WORKING',
    Open: 'OPEN',
    Closed: 'CLOSED'
} as const;

export type InvoiceBasicDtoStatusEnum = typeof InvoiceBasicDtoStatusEnum[keyof typeof InvoiceBasicDtoStatusEnum];
export const InvoiceBasicDtoCostTypeEnum = {
    Shipping: 'SHIPPING',
    FactoryInvoice: 'FACTORY_INVOICE',
    CustomsAndDuties: 'CUSTOMS_AND_DUTIES',
    Inspections: 'INSPECTIONS',
    LocalFreight: 'LOCAL_FREIGHT',
    Photography: 'PHOTOGRAPHY',
    DesignerFees: 'DESIGNER_FEES',
    ProductSamples: 'PRODUCT_SAMPLES',
    ProductTesting: 'PRODUCT_TESTING',
    Storage: 'STORAGE',
    Insurance: 'INSURANCE',
    BankFees: 'BANK_FEES',
    Other: 'OTHER'
} as const;

export type InvoiceBasicDtoCostTypeEnum = typeof InvoiceBasicDtoCostTypeEnum[keyof typeof InvoiceBasicDtoCostTypeEnum];

/**
 * 
 * @export
 * @interface InvoiceDto
 */
export interface InvoiceDto {
    /**
     * 
     * @type {number}
     * @memberof InvoiceDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    'invoiceNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    'date'?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceDto
     */
    'unixDate'?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    'status'?: InvoiceDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    'billToCompany'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    'payReferenceNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    'attention'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    'trackingNumber'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceDto
     */
    'reconciled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    'costType'?: InvoiceDtoCostTypeEnum;
    /**
     * 
     * @type {Array<InvoiceLine>}
     * @memberof InvoiceDto
     */
    'invoiceLines'?: Array<InvoiceLine>;
    /**
     * 
     * @type {number}
     * @memberof InvoiceDto
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceDto
     */
    'accountId'?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    'xeroId'?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceDto
     */
    'invoiceTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceDto
     */
    'invoiceLineTotal'?: number;
    /**
     * 
     * @type {SupplierDto}
     * @memberof InvoiceDto
     */
    'supplier'?: SupplierDto;
    /**
     * 
     * @type {number}
     * @memberof InvoiceDto
     */
    'purchaseOrderId'?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceDto
     */
    'shipmentPlanId'?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceDto
     */
    'documentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    'notes'?: string;
}

export const InvoiceDtoStatusEnum = {
    Review: 'REVIEW',
    Working: 'WORKING',
    Open: 'OPEN',
    Closed: 'CLOSED'
} as const;

export type InvoiceDtoStatusEnum = typeof InvoiceDtoStatusEnum[keyof typeof InvoiceDtoStatusEnum];
export const InvoiceDtoCostTypeEnum = {
    Shipping: 'SHIPPING',
    FactoryInvoice: 'FACTORY_INVOICE',
    CustomsAndDuties: 'CUSTOMS_AND_DUTIES',
    Inspections: 'INSPECTIONS',
    LocalFreight: 'LOCAL_FREIGHT',
    Photography: 'PHOTOGRAPHY',
    DesignerFees: 'DESIGNER_FEES',
    ProductSamples: 'PRODUCT_SAMPLES',
    ProductTesting: 'PRODUCT_TESTING',
    Storage: 'STORAGE',
    Insurance: 'INSURANCE',
    BankFees: 'BANK_FEES',
    Other: 'OTHER'
} as const;

export type InvoiceDtoCostTypeEnum = typeof InvoiceDtoCostTypeEnum[keyof typeof InvoiceDtoCostTypeEnum];

/**
 * 
 * @export
 * @interface InvoiceLine
 */
export interface InvoiceLine {
    /**
     * 
     * @type {number}
     * @memberof InvoiceLine
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLine
     */
    'account'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLine
     */
    'internalReference'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLine
     */
    'costType'?: InvoiceLineCostTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLine
     */
    'quantity'?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLine
     */
    'perUnitCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLine
     */
    'amount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceLine
     */
    'isExcludedFromProductCostsData'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceLine
     */
    'isExcludedFromGeneralCostsData'?: boolean;
    /**
     * 
     * @type {Product}
     * @memberof InvoiceLine
     */
    'product'?: Product;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLine
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLine
     */
    'accountId'?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLine
     */
    'orderId'?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLine
     */
    'unixCreatedAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLine
     */
    'unixUpdatedAt'?: number;
}

export const InvoiceLineCostTypeEnum = {
    Shipping: 'SHIPPING',
    FactoryInvoice: 'FACTORY_INVOICE',
    CustomsAndDuties: 'CUSTOMS_AND_DUTIES',
    Inspections: 'INSPECTIONS',
    LocalFreight: 'LOCAL_FREIGHT',
    Photography: 'PHOTOGRAPHY',
    DesignerFees: 'DESIGNER_FEES',
    ProductSamples: 'PRODUCT_SAMPLES',
    ProductTesting: 'PRODUCT_TESTING',
    Storage: 'STORAGE',
    Insurance: 'INSURANCE',
    BankFees: 'BANK_FEES',
    Other: 'OTHER'
} as const;

export type InvoiceLineCostTypeEnum = typeof InvoiceLineCostTypeEnum[keyof typeof InvoiceLineCostTypeEnum];

/**
 * 
 * @export
 * @interface InvoiceLineDto
 */
export interface InvoiceLineDto {
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    'account'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    'internalReference'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    'costType'?: InvoiceLineDtoCostTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineDto
     */
    'quantity'?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineDto
     */
    'perUnitCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineDto
     */
    'amount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceLineDto
     */
    'isExcludedFromProductCostsData'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceLineDto
     */
    'isExcludedFromGeneralCostsData'?: boolean;
    /**
     * 
     * @type {Product}
     * @memberof InvoiceLineDto
     */
    'product'?: Product;
    /**
     * 
     * @type {Invoice}
     * @memberof InvoiceLineDto
     */
    'invoice'?: Invoice;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineDto
     */
    'accountId'?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineDto
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineDto
     */
    'orderId'?: number;
}

export const InvoiceLineDtoCostTypeEnum = {
    Shipping: 'SHIPPING',
    FactoryInvoice: 'FACTORY_INVOICE',
    CustomsAndDuties: 'CUSTOMS_AND_DUTIES',
    Inspections: 'INSPECTIONS',
    LocalFreight: 'LOCAL_FREIGHT',
    Photography: 'PHOTOGRAPHY',
    DesignerFees: 'DESIGNER_FEES',
    ProductSamples: 'PRODUCT_SAMPLES',
    ProductTesting: 'PRODUCT_TESTING',
    Storage: 'STORAGE',
    Insurance: 'INSURANCE',
    BankFees: 'BANK_FEES',
    Other: 'OTHER'
} as const;

export type InvoiceLineDtoCostTypeEnum = typeof InvoiceLineDtoCostTypeEnum[keyof typeof InvoiceLineDtoCostTypeEnum];

/**
 * 
 * @export
 * @interface InvoicePayment
 */
export interface InvoicePayment {
    /**
     * 
     * @type {number}
     * @memberof InvoicePayment
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoicePayment
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoicePayment
     */
    'accountId'?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoicePayment
     */
    'unixDueDate'?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoicePayment
     */
    'xeroId'?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoicePayment
     */
    'total'?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoicePayment
     */
    'paidAdjusted'?: number;
    /**
     * 
     * @type {AccountCode}
     * @memberof InvoicePayment
     */
    'accountCode'?: AccountCode;
    /**
     * 
     * @type {number}
     * @memberof InvoicePayment
     */
    'balance'?: number;
    /**
     * 
     * @type {Invoice}
     * @memberof InvoicePayment
     */
    'invoice'?: Invoice;
    /**
     * 
     * @type {User}
     * @memberof InvoicePayment
     */
    'assignedToUser'?: User;
    /**
     * 
     * @type {User}
     * @memberof InvoicePayment
     */
    'createdByUser'?: User;
    /**
     * 
     * @type {Array<Payment>}
     * @memberof InvoicePayment
     */
    'payments'?: Array<Payment>;
    /**
     * 
     * @type {string}
     * @memberof InvoicePayment
     */
    'invoicePaymentStatus'?: InvoicePaymentInvoicePaymentStatusEnum;
}

export const InvoicePaymentInvoicePaymentStatusEnum = {
    Paid: 'PAID',
    Balance: 'BALANCE',
    Closed: 'CLOSED',
    Overpaid: 'OVERPAID'
} as const;

export type InvoicePaymentInvoicePaymentStatusEnum = typeof InvoicePaymentInvoicePaymentStatusEnum[keyof typeof InvoicePaymentInvoicePaymentStatusEnum];

/**
 * 
 * @export
 * @interface InvoicePaymentDto
 */
export interface InvoicePaymentDto {
    /**
     * 
     * @type {number}
     * @memberof InvoicePaymentDto
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoicePaymentDto
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoicePaymentDto
     */
    'accountId'?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoicePaymentDto
     */
    'unixDueDate'?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoicePaymentDto
     */
    'dueDate'?: string;
    /**
     * 
     * @type {UserDto}
     * @memberof InvoicePaymentDto
     */
    'assignedToUser'?: UserDto;
    /**
     * 
     * @type {UserDto}
     * @memberof InvoicePaymentDto
     */
    'createdByUser'?: UserDto;
    /**
     * 
     * @type {Array<PaymentDto>}
     * @memberof InvoicePaymentDto
     */
    'payments'?: Array<PaymentDto>;
    /**
     * 
     * @type {PaymentServiceInvoiceDto}
     * @memberof InvoicePaymentDto
     */
    'invoice'?: PaymentServiceInvoiceDto;
    /**
     * 
     * @type {number}
     * @memberof InvoicePaymentDto
     */
    'total'?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoicePaymentDto
     */
    'paidAdjusted'?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoicePaymentDto
     */
    'balance'?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoicePaymentDto
     */
    'invoicePaymentStatus'?: InvoicePaymentDtoInvoicePaymentStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof InvoicePaymentDto
     */
    'xeroId'?: string;
    /**
     * 
     * @type {AccountCode}
     * @memberof InvoicePaymentDto
     */
    'accountCode'?: AccountCode;
    /**
     * 
     * @type {number}
     * @memberof InvoicePaymentDto
     */
    'documentId'?: number;
}

export const InvoicePaymentDtoInvoicePaymentStatusEnum = {
    Paid: 'PAID',
    Balance: 'BALANCE',
    Closed: 'CLOSED',
    Overpaid: 'OVERPAID'
} as const;

export type InvoicePaymentDtoInvoicePaymentStatusEnum = typeof InvoicePaymentDtoInvoicePaymentStatusEnum[keyof typeof InvoicePaymentDtoInvoicePaymentStatusEnum];

/**
 * 
 * @export
 * @interface NotificationDto
 */
export interface NotificationDto {
    /**
     * 
     * @type {number}
     * @memberof NotificationDto
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotificationDto
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotificationDto
     */
    'accountId'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'notificationStatus'?: NotificationDtoNotificationStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'summary'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'notificationType'?: NotificationDtoNotificationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'notificationSource'?: NotificationDtoNotificationSourceEnum;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'entityType'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'messageDateTime'?: string;
}

export const NotificationDtoNotificationStatusEnum = {
    Unread: 'UNREAD',
    Read: 'READ',
    Dismissed: 'DISMISSED'
} as const;

export type NotificationDtoNotificationStatusEnum = typeof NotificationDtoNotificationStatusEnum[keyof typeof NotificationDtoNotificationStatusEnum];
export const NotificationDtoNotificationTypeEnum = {
    Info: 'INFO',
    ActionNeeded: 'ACTION_NEEDED',
    Error: 'ERROR'
} as const;

export type NotificationDtoNotificationTypeEnum = typeof NotificationDtoNotificationTypeEnum[keyof typeof NotificationDtoNotificationTypeEnum];
export const NotificationDtoNotificationSourceEnum = {
    GatewayService: 'GATEWAY_SERVICE',
    InvoiceService: 'INVOICE_SERVICE',
    NotificationService: 'NOTIFICATION_SERVICE',
    ProductService: 'PRODUCT_SERVICE',
    ReportingService: 'REPORTING_SERVICE',
    SchedulerService: 'SCHEDULER_SERVICE',
    SupplierService: 'SUPPLIER_SERVICE',
    ShopifyService: 'SHOPIFY_SERVICE',
    AmazonService: 'AMAZON_SERVICE',
    UserService: 'USER_SERVICE',
    XeroService: 'XERO_SERVICE',
    OrdersService: 'ORDERS_SERVICE',
    Sv1Service: 'SV1_SERVICE',
    PurchasingService: 'PURCHASING_SERVICE'
} as const;

export type NotificationDtoNotificationSourceEnum = typeof NotificationDtoNotificationSourceEnum[keyof typeof NotificationDtoNotificationSourceEnum];

/**
 * 
 * @export
 * @interface PageInvoice
 */
export interface PageInvoice {
    /**
     * 
     * @type {number}
     * @memberof PageInvoice
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageInvoice
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageInvoice
     */
    'size'?: number;
    /**
     * 
     * @type {Array<Invoice>}
     * @memberof PageInvoice
     */
    'content'?: Array<Invoice>;
    /**
     * 
     * @type {number}
     * @memberof PageInvoice
     */
    'number'?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageInvoice
     */
    'sort'?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageInvoice
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageInvoice
     */
    'pageable'?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PageInvoice
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageInvoice
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageInvoice
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageInvoicePaymentDto
 */
export interface PageInvoicePaymentDto {
    /**
     * 
     * @type {number}
     * @memberof PageInvoicePaymentDto
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageInvoicePaymentDto
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageInvoicePaymentDto
     */
    'size'?: number;
    /**
     * 
     * @type {Array<InvoicePaymentDto>}
     * @memberof PageInvoicePaymentDto
     */
    'content'?: Array<InvoicePaymentDto>;
    /**
     * 
     * @type {number}
     * @memberof PageInvoicePaymentDto
     */
    'number'?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageInvoicePaymentDto
     */
    'sort'?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageInvoicePaymentDto
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageInvoicePaymentDto
     */
    'pageable'?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PageInvoicePaymentDto
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageInvoicePaymentDto
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageInvoicePaymentDto
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PagePaymentDto
 */
export interface PagePaymentDto {
    /**
     * 
     * @type {number}
     * @memberof PagePaymentDto
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PagePaymentDto
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PagePaymentDto
     */
    'size'?: number;
    /**
     * 
     * @type {Array<PaymentDto>}
     * @memberof PagePaymentDto
     */
    'content'?: Array<PaymentDto>;
    /**
     * 
     * @type {number}
     * @memberof PagePaymentDto
     */
    'number'?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PagePaymentDto
     */
    'sort'?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PagePaymentDto
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PagePaymentDto
     */
    'pageable'?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PagePaymentDto
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PagePaymentDto
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PagePaymentDto
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface Pageable
 */
export interface Pageable {
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    'offset'?: number;
    /**
     * 
     * @type {Sort}
     * @memberof Pageable
     */
    'sort'?: Sort;
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Pageable
     */
    'paged'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Pageable
     */
    'unpaged'?: boolean;
}
/**
 * 
 * @export
 * @interface Payment
 */
export interface Payment {
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    'invoiceId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    'unixPaymentDate'?: number;
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    'unixDueDate'?: number;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'terms'?: string;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'termsDescription'?: string;
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    'termPercentage'?: number;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'paymentReference'?: string;
    /**
     * 
     * @type {AccountCode}
     * @memberof Payment
     */
    'paidFromAccount'?: AccountCode;
    /**
     * 
     * @type {Currency}
     * @memberof Payment
     */
    'currency'?: Currency;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'paymentStatus'?: PaymentPaymentStatusEnum;
    /**
     * 
     * @type {Transaction}
     * @memberof Payment
     */
    'transaction'?: Transaction;
    /**
     * 
     * @type {InvoicePayment}
     * @memberof Payment
     */
    'invoicePayment'?: InvoicePayment;
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    'accountId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'xeroId'?: string;
}

export const PaymentPaymentStatusEnum = {
    Pay: 'PAY',
    Paid: 'PAID',
    Overdue: 'OVERDUE',
    Pending: 'PENDING'
} as const;

export type PaymentPaymentStatusEnum = typeof PaymentPaymentStatusEnum[keyof typeof PaymentPaymentStatusEnum];

/**
 * 
 * @export
 * @interface PaymentConfirmationDto
 */
export interface PaymentConfirmationDto {
    /**
     * 
     * @type {boolean}
     * @memberof PaymentConfirmationDto
     */
    'isConfirmed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PaymentConfirmationDto
     */
    'paymentReference'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PaymentConfirmationDto
     */
    'confirmationEmailToAddresses'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PaymentConfirmationDto
     */
    'confirmationEmailCCAddresses'?: Array<string>;
    /**
     * 
     * @type {AccountCode}
     * @memberof PaymentConfirmationDto
     */
    'paidFromAccount'?: AccountCode;
}
/**
 * 
 * @export
 * @interface PaymentDto
 */
export interface PaymentDto {
    /**
     * 
     * @type {number}
     * @memberof PaymentDto
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentDto
     */
    'unixPaymentDate'?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentDto
     */
    'unixDueDate'?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentDto
     */
    'invoiceId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentDto
     */
    'terms'?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentDto
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentDto
     */
    'paymentDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentDto
     */
    'dueDate'?: string;
    /**
     * 
     * @type {AccountCode}
     * @memberof PaymentDto
     */
    'paidFromAccount'?: AccountCode;
    /**
     * 
     * @type {Currency}
     * @memberof PaymentDto
     */
    'currency'?: Currency;
    /**
     * 
     * @type {string}
     * @memberof PaymentDto
     */
    'paymentStatus'?: PaymentDtoPaymentStatusEnum;
    /**
     * 
     * @type {InvoicePaymentDto}
     * @memberof PaymentDto
     */
    'invoicePayment'?: InvoicePaymentDto;
    /**
     * 
     * @type {string}
     * @memberof PaymentDto
     */
    'paymentReference'?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentDto
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentDto
     */
    'accountId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentDto
     */
    'xeroId'?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentDto
     */
    'documentId'?: number;
}

export const PaymentDtoPaymentStatusEnum = {
    Pay: 'PAY',
    Paid: 'PAID',
    Overdue: 'OVERDUE',
    Pending: 'PENDING'
} as const;

export type PaymentDtoPaymentStatusEnum = typeof PaymentDtoPaymentStatusEnum[keyof typeof PaymentDtoPaymentStatusEnum];

/**
 * 
 * @export
 * @interface PaymentPatchDto
 */
export interface PaymentPatchDto {
    /**
     * 
     * @type {number}
     * @memberof PaymentPatchDto
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentPatchDto
     */
    'unixPaymentDate'?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentPatchDto
     */
    'unixDueDate'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentPatchDto
     */
    'termsDescription'?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentPatchDto
     */
    'amount'?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentPatchDto
     */
    'termPercentage'?: number;
    /**
     * 
     * @type {AccountCode}
     * @memberof PaymentPatchDto
     */
    'paidFromAccount'?: AccountCode;
    /**
     * 
     * @type {Currency}
     * @memberof PaymentPatchDto
     */
    'currency'?: Currency;
}
/**
 * 
 * @export
 * @interface PaymentServiceInvoiceDto
 */
export interface PaymentServiceInvoiceDto {
    /**
     * 
     * @type {number}
     * @memberof PaymentServiceInvoiceDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentServiceInvoiceDto
     */
    'invoiceNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentServiceInvoiceDto
     */
    'date'?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentServiceInvoiceDto
     */
    'unixDate'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentServiceInvoiceDto
     */
    'invoiceStatus'?: PaymentServiceInvoiceDtoInvoiceStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PaymentServiceInvoiceDto
     */
    'billToCompany'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentServiceInvoiceDto
     */
    'payReferenceNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentServiceInvoiceDto
     */
    'attention'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentServiceInvoiceDto
     */
    'trackingNumber'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentServiceInvoiceDto
     */
    'reconciled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PaymentServiceInvoiceDto
     */
    'costType'?: PaymentServiceInvoiceDtoCostTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof PaymentServiceInvoiceDto
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentServiceInvoiceDto
     */
    'accountId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentServiceInvoiceDto
     */
    'xeroId'?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentServiceInvoiceDto
     */
    'invoiceTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentServiceInvoiceDto
     */
    'invoiceLineTotal'?: number;
    /**
     * 
     * @type {SupplierDto}
     * @memberof PaymentServiceInvoiceDto
     */
    'supplier'?: SupplierDto;
}

export const PaymentServiceInvoiceDtoInvoiceStatusEnum = {
    Review: 'REVIEW',
    Working: 'WORKING',
    Open: 'OPEN',
    Closed: 'CLOSED'
} as const;

export type PaymentServiceInvoiceDtoInvoiceStatusEnum = typeof PaymentServiceInvoiceDtoInvoiceStatusEnum[keyof typeof PaymentServiceInvoiceDtoInvoiceStatusEnum];
export const PaymentServiceInvoiceDtoCostTypeEnum = {
    Shipping: 'SHIPPING',
    FactoryInvoice: 'FACTORY_INVOICE',
    CustomsAndDuties: 'CUSTOMS_AND_DUTIES',
    Inspections: 'INSPECTIONS',
    LocalFreight: 'LOCAL_FREIGHT',
    Photography: 'PHOTOGRAPHY',
    DesignerFees: 'DESIGNER_FEES',
    ProductSamples: 'PRODUCT_SAMPLES',
    ProductTesting: 'PRODUCT_TESTING',
    Storage: 'STORAGE',
    Insurance: 'INSURANCE',
    BankFees: 'BANK_FEES',
    Other: 'OTHER'
} as const;

export type PaymentServiceInvoiceDtoCostTypeEnum = typeof PaymentServiceInvoiceDtoCostTypeEnum[keyof typeof PaymentServiceInvoiceDtoCostTypeEnum];

/**
 * 
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'sku'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'fnSku'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'asin'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'tag'?: string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'accountId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    'isVariant'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    'hasVariants'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'rootProductId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'skuAlias'?: string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'landedUnitCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'factoryUnitCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'inboundUnitCost'?: number;
}
/**
 * 
 * @export
 * @interface Resource
 */
export interface Resource {
    /**
     * 
     * @type {boolean}
     * @memberof Resource
     */
    'open'?: boolean;
    /**
     * 
     * @type {File}
     * @memberof Resource
     */
    'file'?: File;
    /**
     * 
     * @type {boolean}
     * @memberof Resource
     */
    'readable'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    'uri'?: string;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    'filename'?: string;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    'description'?: string;
    /**
     * 
     * @type {object}
     * @memberof Resource
     */
    'inputStream'?: object;
}
/**
 * 
 * @export
 * @interface Sort
 */
export interface Sort {
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    'sorted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    'unsorted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface Supplier
 */
export interface Supplier {
    /**
     * 
     * @type {number}
     * @memberof Supplier
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    'telephoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    'companyName'?: string;
    /**
     * 
     * @type {number}
     * @memberof Supplier
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Supplier
     */
    'accountId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    'xeroId'?: string;
    /**
     * 
     * @type {Currency}
     * @memberof Supplier
     */
    'preferredCurrency'?: Currency;
}
/**
 * 
 * @export
 * @interface SupplierDto
 */
export interface SupplierDto {
    /**
     * 
     * @type {number}
     * @memberof SupplierDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof SupplierDto
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierDto
     */
    'telephoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierDto
     */
    'companyName'?: string;
    /**
     * 
     * @type {number}
     * @memberof SupplierDto
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof SupplierDto
     */
    'accountId'?: number;
    /**
     * 
     * @type {string}
     * @memberof SupplierDto
     */
    'xeroId'?: string;
}
/**
 * 
 * @export
 * @interface Transaction
 */
export interface Transaction {
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    'accountId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    'invoiceId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    'amount'?: number;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    'unixTransactionDate'?: number;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    'transactionStatus'?: TransactionTransactionStatusEnum;
    /**
     * 
     * @type {Payment}
     * @memberof Transaction
     */
    'payment'?: Payment;
}

export const TransactionTransactionStatusEnum = {
    Paid: 'PAID',
    Balance: 'BALANCE'
} as const;

export type TransactionTransactionStatusEnum = typeof TransactionTransactionStatusEnum[keyof typeof TransactionTransactionStatusEnum];

/**
 * 
 * @export
 * @interface UploadNewInvoiceDocumentRequest
 */
export interface UploadNewInvoiceDocumentRequest {
    /**
     * 
     * @type {File}
     * @memberof UploadNewInvoiceDocumentRequest
     */
    'file'?: File;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'accountName'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'dateOfBirth'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'password'?: string;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'currentAccountId'?: number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'ownedAccountId'?: number;
}
/**
 * 
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'accountName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'dateOfBirth'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    'currentAccountId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    'ownedAccountId'?: number;
}

/**
 * CostTypeControllerApi - axios parameter creator
 * @export
 */
export const CostTypeControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCostTypes: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getAllCostTypes', 'userId', userId)
            const localVarPath = `/invoices/{userId}/cost-types`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllImportTypes: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getAllImportTypes', 'userId', userId)
            const localVarPath = `/invoices/{userId}/import-types`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CostTypeControllerApi - functional programming interface
 * @export
 */
export const CostTypeControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CostTypeControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCostTypes(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCostTypes(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CostTypeControllerApi.getAllCostTypes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllImportTypes(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllImportTypes(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CostTypeControllerApi.getAllImportTypes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CostTypeControllerApi - factory interface
 * @export
 */
export const CostTypeControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CostTypeControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCostTypes(userId: number, options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.getAllCostTypes(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllImportTypes(userId: number, options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.getAllImportTypes(userId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CostTypeControllerApi - object-oriented interface
 * @export
 * @class CostTypeControllerApi
 * @extends {BaseAPI}
 */
export class CostTypeControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostTypeControllerApi
     */
    public getAllCostTypes(userId: number, options?: RawAxiosRequestConfig) {
        return CostTypeControllerApiFp(this.configuration).getAllCostTypes(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostTypeControllerApi
     */
    public getAllImportTypes(userId: number, options?: RawAxiosRequestConfig) {
        return CostTypeControllerApiFp(this.configuration).getAllImportTypes(userId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * InvoiceAdminControllerApi - axios parameter creator
 * @export
 */
export const InvoiceAdminControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadSerialisedInvoiceDataForAccount: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('downloadSerialisedInvoiceDataForAccount', 'userId', userId)
            const localVarPath = `/admin/invoices/{userId}/vending`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fixInvoiceNamesAfterImportForUser: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('fixInvoiceNamesAfterImportForUser', 'userId', userId)
            const localVarPath = `/admin/invoices/{userId}/vending/fix-names`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncAllInvoices: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/invoices/sync/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncAllPayments: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/invoices/payments/sync/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncInvoicesByUser: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('syncInvoicesByUser', 'userId', userId)
            const localVarPath = `/admin/invoices/{userId}/sync`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncPaymentsForUser: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('syncPaymentsForUser', 'userId', userId)
            const localVarPath = `/admin/invoices/{userId}/payments/sync`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {UploadNewInvoiceDocumentRequest} [uploadNewInvoiceDocumentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadSerialisedInvoiceDataForAccount: async (userId: number, uploadNewInvoiceDocumentRequest?: UploadNewInvoiceDocumentRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('uploadSerialisedInvoiceDataForAccount', 'userId', userId)
            const localVarPath = `/admin/invoices/{userId}/vending`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uploadNewInvoiceDocumentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvoiceAdminControllerApi - functional programming interface
 * @export
 */
export const InvoiceAdminControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvoiceAdminControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadSerialisedInvoiceDataForAccount(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Resource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadSerialisedInvoiceDataForAccount(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvoiceAdminControllerApi.downloadSerialisedInvoiceDataForAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fixInvoiceNamesAfterImportForUser(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: boolean; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fixInvoiceNamesAfterImportForUser(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvoiceAdminControllerApi.fixInvoiceNamesAfterImportForUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncAllInvoices(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncAllInvoices(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvoiceAdminControllerApi.syncAllInvoices']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncAllPayments(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: number; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncAllPayments(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvoiceAdminControllerApi.syncAllPayments']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncInvoicesByUser(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: boolean; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncInvoicesByUser(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvoiceAdminControllerApi.syncInvoicesByUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncPaymentsForUser(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: number; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncPaymentsForUser(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvoiceAdminControllerApi.syncPaymentsForUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {UploadNewInvoiceDocumentRequest} [uploadNewInvoiceDocumentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadSerialisedInvoiceDataForAccount(userId: number, uploadNewInvoiceDocumentRequest?: UploadNewInvoiceDocumentRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: boolean; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadSerialisedInvoiceDataForAccount(userId, uploadNewInvoiceDocumentRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvoiceAdminControllerApi.uploadSerialisedInvoiceDataForAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * InvoiceAdminControllerApi - factory interface
 * @export
 */
export const InvoiceAdminControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvoiceAdminControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadSerialisedInvoiceDataForAccount(userId: number, options?: any): AxiosPromise<Resource> {
            return localVarFp.downloadSerialisedInvoiceDataForAccount(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fixInvoiceNamesAfterImportForUser(userId: number, options?: any): AxiosPromise<{ [key: string]: boolean; }> {
            return localVarFp.fixInvoiceNamesAfterImportForUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncAllInvoices(options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.syncAllInvoices(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncAllPayments(options?: any): AxiosPromise<{ [key: string]: number; }> {
            return localVarFp.syncAllPayments(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncInvoicesByUser(userId: number, options?: any): AxiosPromise<{ [key: string]: boolean; }> {
            return localVarFp.syncInvoicesByUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncPaymentsForUser(userId: number, options?: any): AxiosPromise<{ [key: string]: number; }> {
            return localVarFp.syncPaymentsForUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {UploadNewInvoiceDocumentRequest} [uploadNewInvoiceDocumentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadSerialisedInvoiceDataForAccount(userId: number, uploadNewInvoiceDocumentRequest?: UploadNewInvoiceDocumentRequest, options?: any): AxiosPromise<{ [key: string]: boolean; }> {
            return localVarFp.uploadSerialisedInvoiceDataForAccount(userId, uploadNewInvoiceDocumentRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvoiceAdminControllerApi - object-oriented interface
 * @export
 * @class InvoiceAdminControllerApi
 * @extends {BaseAPI}
 */
export class InvoiceAdminControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceAdminControllerApi
     */
    public downloadSerialisedInvoiceDataForAccount(userId: number, options?: RawAxiosRequestConfig) {
        return InvoiceAdminControllerApiFp(this.configuration).downloadSerialisedInvoiceDataForAccount(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceAdminControllerApi
     */
    public fixInvoiceNamesAfterImportForUser(userId: number, options?: RawAxiosRequestConfig) {
        return InvoiceAdminControllerApiFp(this.configuration).fixInvoiceNamesAfterImportForUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceAdminControllerApi
     */
    public syncAllInvoices(options?: RawAxiosRequestConfig) {
        return InvoiceAdminControllerApiFp(this.configuration).syncAllInvoices(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceAdminControllerApi
     */
    public syncAllPayments(options?: RawAxiosRequestConfig) {
        return InvoiceAdminControllerApiFp(this.configuration).syncAllPayments(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceAdminControllerApi
     */
    public syncInvoicesByUser(userId: number, options?: RawAxiosRequestConfig) {
        return InvoiceAdminControllerApiFp(this.configuration).syncInvoicesByUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceAdminControllerApi
     */
    public syncPaymentsForUser(userId: number, options?: RawAxiosRequestConfig) {
        return InvoiceAdminControllerApiFp(this.configuration).syncPaymentsForUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {UploadNewInvoiceDocumentRequest} [uploadNewInvoiceDocumentRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceAdminControllerApi
     */
    public uploadSerialisedInvoiceDataForAccount(userId: number, uploadNewInvoiceDocumentRequest?: UploadNewInvoiceDocumentRequest, options?: RawAxiosRequestConfig) {
        return InvoiceAdminControllerApiFp(this.configuration).uploadSerialisedInvoiceDataForAccount(userId, uploadNewInvoiceDocumentRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * InvoiceControllerApi - axios parameter creator
 * @export
 */
export const InvoiceControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} userId 
         * @param {CreateInvoiceFromPoOrSupplierDto} [createInvoiceFromPoOrSupplierDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewInvoiceFromPurchaseOrderOrSupplierForUser: async (userId: number, createInvoiceFromPoOrSupplierDto?: CreateInvoiceFromPoOrSupplierDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('createNewInvoiceFromPurchaseOrderOrSupplierForUser', 'userId', userId)
            const localVarPath = `/invoices/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createInvoiceFromPoOrSupplierDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInvoice: async (userId: number, id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteInvoice', 'userId', userId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteInvoice', 'id', id)
            const localVarPath = `/invoices/{userId}/{id}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {number} userId 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllInvoices: async (pageable: Pageable, userId: number, search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('getAllInvoices', 'pageable', pageable)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getAllInvoices', 'userId', userId)
            const localVarPath = `/invoices/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoiceById: async (userId: number, id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getInvoiceById', 'userId', userId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getInvoiceById', 'id', id)
            const localVarPath = `/invoices/{userId}/{id}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} id 
         * @param {ImportGoogleSheetDto} [importGoogleSheetDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importInvoiceLinesFromGoogleSheet: async (userId: number, id: number, importGoogleSheetDto?: ImportGoogleSheetDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('importInvoiceLinesFromGoogleSheet', 'userId', userId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('importInvoiceLinesFromGoogleSheet', 'id', id)
            const localVarPath = `/invoices/{userId}/{id}/import-invoice-google-sheet`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(importGoogleSheetDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} id 
         * @param {InvoiceBasicDto} [invoiceBasicDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceBasicInvoice: async (userId: number, id: number, invoiceBasicDto?: InvoiceBasicDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('replaceBasicInvoice', 'userId', userId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('replaceBasicInvoice', 'id', id)
            const localVarPath = `/invoices/{userId}/{id}/basic`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(invoiceBasicDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} id 
         * @param {InvoiceDto} [invoiceDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceInvoice: async (userId: number, id: number, invoiceDto?: InvoiceDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('replaceInvoice', 'userId', userId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('replaceInvoice', 'id', id)
            const localVarPath = `/invoices/{userId}/{id}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(invoiceDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {NotificationDto} [notificationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendNotification: async (userId: number, notificationDto?: NotificationDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('sendNotification', 'userId', userId)
            const localVarPath = `/invoices/{userId}/notification`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvoiceControllerApi - functional programming interface
 * @export
 */
export const InvoiceControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvoiceControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {CreateInvoiceFromPoOrSupplierDto} [createInvoiceFromPoOrSupplierDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNewInvoiceFromPurchaseOrderOrSupplierForUser(userId: number, createInvoiceFromPoOrSupplierDto?: CreateInvoiceFromPoOrSupplierDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Invoice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNewInvoiceFromPurchaseOrderOrSupplierForUser(userId, createInvoiceFromPoOrSupplierDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvoiceControllerApi.createNewInvoiceFromPurchaseOrderOrSupplierForUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteInvoice(userId: number, id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: boolean; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteInvoice(userId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvoiceControllerApi.deleteInvoice']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {number} userId 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllInvoices(pageable: Pageable, userId: number, search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageInvoice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllInvoices(pageable, userId, search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvoiceControllerApi.getAllInvoices']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvoiceById(userId: number, id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Invoice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvoiceById(userId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvoiceControllerApi.getInvoiceById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} id 
         * @param {ImportGoogleSheetDto} [importGoogleSheetDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importInvoiceLinesFromGoogleSheet(userId: number, id: number, importGoogleSheetDto?: ImportGoogleSheetDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Invoice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importInvoiceLinesFromGoogleSheet(userId, id, importGoogleSheetDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvoiceControllerApi.importInvoiceLinesFromGoogleSheet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} id 
         * @param {InvoiceBasicDto} [invoiceBasicDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceBasicInvoice(userId: number, id: number, invoiceBasicDto?: InvoiceBasicDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Invoice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.replaceBasicInvoice(userId, id, invoiceBasicDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvoiceControllerApi.replaceBasicInvoice']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} id 
         * @param {InvoiceDto} [invoiceDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceInvoice(userId: number, id: number, invoiceDto?: InvoiceDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Invoice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.replaceInvoice(userId, id, invoiceDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvoiceControllerApi.replaceInvoice']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {NotificationDto} [notificationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendNotification(userId: number, notificationDto?: NotificationDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendNotification(userId, notificationDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvoiceControllerApi.sendNotification']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * InvoiceControllerApi - factory interface
 * @export
 */
export const InvoiceControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvoiceControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {CreateInvoiceFromPoOrSupplierDto} [createInvoiceFromPoOrSupplierDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewInvoiceFromPurchaseOrderOrSupplierForUser(userId: number, createInvoiceFromPoOrSupplierDto?: CreateInvoiceFromPoOrSupplierDto, options?: any): AxiosPromise<Invoice> {
            return localVarFp.createNewInvoiceFromPurchaseOrderOrSupplierForUser(userId, createInvoiceFromPoOrSupplierDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInvoice(userId: number, id: number, options?: any): AxiosPromise<{ [key: string]: boolean; }> {
            return localVarFp.deleteInvoice(userId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {number} userId 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllInvoices(pageable: Pageable, userId: number, search?: string, options?: any): AxiosPromise<PageInvoice> {
            return localVarFp.getAllInvoices(pageable, userId, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoiceById(userId: number, id: number, options?: any): AxiosPromise<Invoice> {
            return localVarFp.getInvoiceById(userId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} id 
         * @param {ImportGoogleSheetDto} [importGoogleSheetDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importInvoiceLinesFromGoogleSheet(userId: number, id: number, importGoogleSheetDto?: ImportGoogleSheetDto, options?: any): AxiosPromise<Invoice> {
            return localVarFp.importInvoiceLinesFromGoogleSheet(userId, id, importGoogleSheetDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} id 
         * @param {InvoiceBasicDto} [invoiceBasicDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceBasicInvoice(userId: number, id: number, invoiceBasicDto?: InvoiceBasicDto, options?: any): AxiosPromise<Invoice> {
            return localVarFp.replaceBasicInvoice(userId, id, invoiceBasicDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} id 
         * @param {InvoiceDto} [invoiceDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceInvoice(userId: number, id: number, invoiceDto?: InvoiceDto, options?: any): AxiosPromise<Invoice> {
            return localVarFp.replaceInvoice(userId, id, invoiceDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {NotificationDto} [notificationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendNotification(userId: number, notificationDto?: NotificationDto, options?: any): AxiosPromise<NotificationDto> {
            return localVarFp.sendNotification(userId, notificationDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvoiceControllerApi - object-oriented interface
 * @export
 * @class InvoiceControllerApi
 * @extends {BaseAPI}
 */
export class InvoiceControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} userId 
     * @param {CreateInvoiceFromPoOrSupplierDto} [createInvoiceFromPoOrSupplierDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceControllerApi
     */
    public createNewInvoiceFromPurchaseOrderOrSupplierForUser(userId: number, createInvoiceFromPoOrSupplierDto?: CreateInvoiceFromPoOrSupplierDto, options?: RawAxiosRequestConfig) {
        return InvoiceControllerApiFp(this.configuration).createNewInvoiceFromPurchaseOrderOrSupplierForUser(userId, createInvoiceFromPoOrSupplierDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceControllerApi
     */
    public deleteInvoice(userId: number, id: number, options?: RawAxiosRequestConfig) {
        return InvoiceControllerApiFp(this.configuration).deleteInvoice(userId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pageable} pageable 
     * @param {number} userId 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceControllerApi
     */
    public getAllInvoices(pageable: Pageable, userId: number, search?: string, options?: RawAxiosRequestConfig) {
        return InvoiceControllerApiFp(this.configuration).getAllInvoices(pageable, userId, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceControllerApi
     */
    public getInvoiceById(userId: number, id: number, options?: RawAxiosRequestConfig) {
        return InvoiceControllerApiFp(this.configuration).getInvoiceById(userId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {number} id 
     * @param {ImportGoogleSheetDto} [importGoogleSheetDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceControllerApi
     */
    public importInvoiceLinesFromGoogleSheet(userId: number, id: number, importGoogleSheetDto?: ImportGoogleSheetDto, options?: RawAxiosRequestConfig) {
        return InvoiceControllerApiFp(this.configuration).importInvoiceLinesFromGoogleSheet(userId, id, importGoogleSheetDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {number} id 
     * @param {InvoiceBasicDto} [invoiceBasicDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceControllerApi
     */
    public replaceBasicInvoice(userId: number, id: number, invoiceBasicDto?: InvoiceBasicDto, options?: RawAxiosRequestConfig) {
        return InvoiceControllerApiFp(this.configuration).replaceBasicInvoice(userId, id, invoiceBasicDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {number} id 
     * @param {InvoiceDto} [invoiceDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceControllerApi
     */
    public replaceInvoice(userId: number, id: number, invoiceDto?: InvoiceDto, options?: RawAxiosRequestConfig) {
        return InvoiceControllerApiFp(this.configuration).replaceInvoice(userId, id, invoiceDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {NotificationDto} [notificationDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceControllerApi
     */
    public sendNotification(userId: number, notificationDto?: NotificationDto, options?: RawAxiosRequestConfig) {
        return InvoiceControllerApiFp(this.configuration).sendNotification(userId, notificationDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * InvoiceDocumentControllerApi - axios parameter creator
 * @export
 */
export const InvoiceDocumentControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} userId 
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadInvoiceDocument: async (userId: number, invoiceId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('downloadInvoiceDocument', 'userId', userId)
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('downloadInvoiceDocument', 'invoiceId', invoiceId)
            const localVarPath = `/invoices/{userId}/{invoiceId}/document`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {UploadNewInvoiceDocumentRequest} [uploadNewInvoiceDocumentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadNewInvoiceDocument: async (userId: number, uploadNewInvoiceDocumentRequest?: UploadNewInvoiceDocumentRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('uploadNewInvoiceDocument', 'userId', userId)
            const localVarPath = `/invoices/{userId}/upload`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uploadNewInvoiceDocumentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} invoiceId 
         * @param {UploadNewInvoiceDocumentRequest} [uploadNewInvoiceDocumentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadReplacementInvoiceDocument: async (userId: number, invoiceId: number, uploadNewInvoiceDocumentRequest?: UploadNewInvoiceDocumentRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('uploadReplacementInvoiceDocument', 'userId', userId)
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('uploadReplacementInvoiceDocument', 'invoiceId', invoiceId)
            const localVarPath = `/invoices/{userId}/{invoiceId}/upload`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uploadNewInvoiceDocumentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvoiceDocumentControllerApi - functional programming interface
 * @export
 */
export const InvoiceDocumentControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvoiceDocumentControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadInvoiceDocument(userId: number, invoiceId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Resource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadInvoiceDocument(userId, invoiceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvoiceDocumentControllerApi.downloadInvoiceDocument']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {UploadNewInvoiceDocumentRequest} [uploadNewInvoiceDocumentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadNewInvoiceDocument(userId: number, uploadNewInvoiceDocumentRequest?: UploadNewInvoiceDocumentRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Invoice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadNewInvoiceDocument(userId, uploadNewInvoiceDocumentRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvoiceDocumentControllerApi.uploadNewInvoiceDocument']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} invoiceId 
         * @param {UploadNewInvoiceDocumentRequest} [uploadNewInvoiceDocumentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadReplacementInvoiceDocument(userId: number, invoiceId: number, uploadNewInvoiceDocumentRequest?: UploadNewInvoiceDocumentRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Invoice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadReplacementInvoiceDocument(userId, invoiceId, uploadNewInvoiceDocumentRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvoiceDocumentControllerApi.uploadReplacementInvoiceDocument']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * InvoiceDocumentControllerApi - factory interface
 * @export
 */
export const InvoiceDocumentControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvoiceDocumentControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadInvoiceDocument(userId: number, invoiceId: number, options?: any): AxiosPromise<Resource> {
            return localVarFp.downloadInvoiceDocument(userId, invoiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {UploadNewInvoiceDocumentRequest} [uploadNewInvoiceDocumentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadNewInvoiceDocument(userId: number, uploadNewInvoiceDocumentRequest?: UploadNewInvoiceDocumentRequest, options?: any): AxiosPromise<Invoice> {
            return localVarFp.uploadNewInvoiceDocument(userId, uploadNewInvoiceDocumentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} invoiceId 
         * @param {UploadNewInvoiceDocumentRequest} [uploadNewInvoiceDocumentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadReplacementInvoiceDocument(userId: number, invoiceId: number, uploadNewInvoiceDocumentRequest?: UploadNewInvoiceDocumentRequest, options?: any): AxiosPromise<Invoice> {
            return localVarFp.uploadReplacementInvoiceDocument(userId, invoiceId, uploadNewInvoiceDocumentRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvoiceDocumentControllerApi - object-oriented interface
 * @export
 * @class InvoiceDocumentControllerApi
 * @extends {BaseAPI}
 */
export class InvoiceDocumentControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} userId 
     * @param {number} invoiceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceDocumentControllerApi
     */
    public downloadInvoiceDocument(userId: number, invoiceId: number, options?: RawAxiosRequestConfig) {
        return InvoiceDocumentControllerApiFp(this.configuration).downloadInvoiceDocument(userId, invoiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {UploadNewInvoiceDocumentRequest} [uploadNewInvoiceDocumentRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceDocumentControllerApi
     */
    public uploadNewInvoiceDocument(userId: number, uploadNewInvoiceDocumentRequest?: UploadNewInvoiceDocumentRequest, options?: RawAxiosRequestConfig) {
        return InvoiceDocumentControllerApiFp(this.configuration).uploadNewInvoiceDocument(userId, uploadNewInvoiceDocumentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {number} invoiceId 
     * @param {UploadNewInvoiceDocumentRequest} [uploadNewInvoiceDocumentRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceDocumentControllerApi
     */
    public uploadReplacementInvoiceDocument(userId: number, invoiceId: number, uploadNewInvoiceDocumentRequest?: UploadNewInvoiceDocumentRequest, options?: RawAxiosRequestConfig) {
        return InvoiceDocumentControllerApiFp(this.configuration).uploadReplacementInvoiceDocument(userId, invoiceId, uploadNewInvoiceDocumentRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * InvoiceLineControllerApi - axios parameter creator
 * @export
 */
export const InvoiceLineControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} userId 
         * @param {number} invoiceId 
         * @param {InvoiceLineDto} [invoiceLineDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewInvoiceLine: async (userId: number, invoiceId: number, invoiceLineDto?: InvoiceLineDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('createNewInvoiceLine', 'userId', userId)
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('createNewInvoiceLine', 'invoiceId', invoiceId)
            const localVarPath = `/invoices/{userId}/{invoiceId}/invoice-lines`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(invoiceLineDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} invoiceId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInvoiceLine: async (userId: number, invoiceId: number, id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteInvoiceLine', 'userId', userId)
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('deleteInvoiceLine', 'invoiceId', invoiceId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteInvoiceLine', 'id', id)
            const localVarPath = `/invoices/{userId}/{invoiceId}/invoice-lines/{id}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} invoiceId 
         * @param {number} id 
         * @param {InvoiceLineDto} [invoiceLineDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInvoiceLine: async (userId: number, invoiceId: number, id: number, invoiceLineDto?: InvoiceLineDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateInvoiceLine', 'userId', userId)
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('updateInvoiceLine', 'invoiceId', invoiceId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateInvoiceLine', 'id', id)
            const localVarPath = `/invoices/{userId}/{invoiceId}/invoice-lines/{id}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(invoiceLineDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {Array<InvoiceLine>} [invoiceLine] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInvoiceLines: async (userId: number, invoiceLine?: Array<InvoiceLine>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateInvoiceLines', 'userId', userId)
            const localVarPath = `/invoices/{userId}/invoice-lines`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(invoiceLine, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvoiceLineControllerApi - functional programming interface
 * @export
 */
export const InvoiceLineControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvoiceLineControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {number} invoiceId 
         * @param {InvoiceLineDto} [invoiceLineDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNewInvoiceLine(userId: number, invoiceId: number, invoiceLineDto?: InvoiceLineDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceLine>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNewInvoiceLine(userId, invoiceId, invoiceLineDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvoiceLineControllerApi.createNewInvoiceLine']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} invoiceId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteInvoiceLine(userId: number, invoiceId: number, id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: boolean; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteInvoiceLine(userId, invoiceId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvoiceLineControllerApi.deleteInvoiceLine']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} invoiceId 
         * @param {number} id 
         * @param {InvoiceLineDto} [invoiceLineDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateInvoiceLine(userId: number, invoiceId: number, id: number, invoiceLineDto?: InvoiceLineDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceLine>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateInvoiceLine(userId, invoiceId, id, invoiceLineDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvoiceLineControllerApi.updateInvoiceLine']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {Array<InvoiceLine>} [invoiceLine] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateInvoiceLines(userId: number, invoiceLine?: Array<InvoiceLine>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InvoiceLine>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateInvoiceLines(userId, invoiceLine, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvoiceLineControllerApi.updateInvoiceLines']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * InvoiceLineControllerApi - factory interface
 * @export
 */
export const InvoiceLineControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvoiceLineControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {number} invoiceId 
         * @param {InvoiceLineDto} [invoiceLineDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewInvoiceLine(userId: number, invoiceId: number, invoiceLineDto?: InvoiceLineDto, options?: any): AxiosPromise<InvoiceLine> {
            return localVarFp.createNewInvoiceLine(userId, invoiceId, invoiceLineDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} invoiceId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInvoiceLine(userId: number, invoiceId: number, id: number, options?: any): AxiosPromise<{ [key: string]: boolean; }> {
            return localVarFp.deleteInvoiceLine(userId, invoiceId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} invoiceId 
         * @param {number} id 
         * @param {InvoiceLineDto} [invoiceLineDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInvoiceLine(userId: number, invoiceId: number, id: number, invoiceLineDto?: InvoiceLineDto, options?: any): AxiosPromise<InvoiceLine> {
            return localVarFp.updateInvoiceLine(userId, invoiceId, id, invoiceLineDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {Array<InvoiceLine>} [invoiceLine] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInvoiceLines(userId: number, invoiceLine?: Array<InvoiceLine>, options?: any): AxiosPromise<Array<InvoiceLine>> {
            return localVarFp.updateInvoiceLines(userId, invoiceLine, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvoiceLineControllerApi - object-oriented interface
 * @export
 * @class InvoiceLineControllerApi
 * @extends {BaseAPI}
 */
export class InvoiceLineControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} userId 
     * @param {number} invoiceId 
     * @param {InvoiceLineDto} [invoiceLineDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceLineControllerApi
     */
    public createNewInvoiceLine(userId: number, invoiceId: number, invoiceLineDto?: InvoiceLineDto, options?: RawAxiosRequestConfig) {
        return InvoiceLineControllerApiFp(this.configuration).createNewInvoiceLine(userId, invoiceId, invoiceLineDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {number} invoiceId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceLineControllerApi
     */
    public deleteInvoiceLine(userId: number, invoiceId: number, id: number, options?: RawAxiosRequestConfig) {
        return InvoiceLineControllerApiFp(this.configuration).deleteInvoiceLine(userId, invoiceId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {number} invoiceId 
     * @param {number} id 
     * @param {InvoiceLineDto} [invoiceLineDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceLineControllerApi
     */
    public updateInvoiceLine(userId: number, invoiceId: number, id: number, invoiceLineDto?: InvoiceLineDto, options?: RawAxiosRequestConfig) {
        return InvoiceLineControllerApiFp(this.configuration).updateInvoiceLine(userId, invoiceId, id, invoiceLineDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {Array<InvoiceLine>} [invoiceLine] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceLineControllerApi
     */
    public updateInvoiceLines(userId: number, invoiceLine?: Array<InvoiceLine>, options?: RawAxiosRequestConfig) {
        return InvoiceLineControllerApiFp(this.configuration).updateInvoiceLines(userId, invoiceLine, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PaymentsControllerApi - axios parameter creator
 * @export
 */
export const PaymentsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} userId 
         * @param {InvoicePaymentDto} [invoicePaymentDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvoicePayment: async (userId: number, invoicePaymentDto?: InvoicePaymentDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('createInvoicePayment', 'userId', userId)
            const localVarPath = `/payments/{userId}/invoice-payments`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(invoicePaymentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} invoicePaymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInvoicePayment: async (userId: number, invoicePaymentId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteInvoicePayment', 'userId', userId)
            // verify required parameter 'invoicePaymentId' is not null or undefined
            assertParamExists('deleteInvoicePayment', 'invoicePaymentId', invoicePaymentId)
            const localVarPath = `/payments/{userId}/invoice-payments/{invoicePaymentId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"invoicePaymentId"}}`, encodeURIComponent(String(invoicePaymentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} invoicePaymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchInvoicePaymentForUserById: async (userId: number, invoicePaymentId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('fetchInvoicePaymentForUserById', 'userId', userId)
            // verify required parameter 'invoicePaymentId' is not null or undefined
            assertParamExists('fetchInvoicePaymentForUserById', 'invoicePaymentId', invoicePaymentId)
            const localVarPath = `/payments/{userId}/invoice-payments/{invoicePaymentId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"invoicePaymentId"}}`, encodeURIComponent(String(invoicePaymentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {Pageable} pageable 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchInvoicePaymentsForUser: async (userId: number, pageable: Pageable, search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('fetchInvoicePaymentsForUser', 'userId', userId)
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('fetchInvoicePaymentsForUser', 'pageable', pageable)
            const localVarPath = `/payments/{userId}/invoice-payments`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {Pageable} pageable 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPaymentsForUser: async (userId: number, pageable: Pageable, search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('fetchPaymentsForUser', 'userId', userId)
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('fetchPaymentsForUser', 'pageable', pageable)
            const localVarPath = `/payments/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAccountCodesForUser: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getAllAccountCodesForUser', 'userId', userId)
            const localVarPath = `/payments/{userId}/account-codes`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoiceAccountCodesForUser: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getInvoiceAccountCodesForUser', 'userId', userId)
            const localVarPath = `/payments/{userId}/account-codes/invoice`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentAccountCodesForUser: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getPaymentAccountCodesForUser', 'userId', userId)
            const localVarPath = `/payments/{userId}/account-codes/payment`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentStatusesForUser: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getPaymentStatusesForUser', 'userId', userId)
            const localVarPath = `/payments/{userId}/payment-statuses`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} paymentId 
         * @param {PaymentConfirmationDto} [paymentConfirmationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payPaymentEntry: async (userId: number, paymentId: number, paymentConfirmationDto?: PaymentConfirmationDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('payPaymentEntry', 'userId', userId)
            // verify required parameter 'paymentId' is not null or undefined
            assertParamExists('payPaymentEntry', 'paymentId', paymentId)
            const localVarPath = `/payments/{userId}/{paymentId}/pay`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"paymentId"}}`, encodeURIComponent(String(paymentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paymentConfirmationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} invoicePaymentId 
         * @param {InvoicePaymentDto} [invoicePaymentDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceInvoicePayment: async (userId: number, invoicePaymentId: number, invoicePaymentDto?: InvoicePaymentDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('replaceInvoicePayment', 'userId', userId)
            // verify required parameter 'invoicePaymentId' is not null or undefined
            assertParamExists('replaceInvoicePayment', 'invoicePaymentId', invoicePaymentId)
            const localVarPath = `/payments/{userId}/invoice-payments/{invoicePaymentId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"invoicePaymentId"}}`, encodeURIComponent(String(invoicePaymentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(invoicePaymentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} paymentId 
         * @param {PaymentConfirmationDto} [paymentConfirmationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        undoPaymentEntry: async (userId: number, paymentId: number, paymentConfirmationDto?: PaymentConfirmationDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('undoPaymentEntry', 'userId', userId)
            // verify required parameter 'paymentId' is not null or undefined
            assertParamExists('undoPaymentEntry', 'paymentId', paymentId)
            const localVarPath = `/payments/{userId}/{paymentId}/pay/undo`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"paymentId"}}`, encodeURIComponent(String(paymentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paymentConfirmationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} paymentId 
         * @param {PaymentPatchDto} [paymentPatchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIndividualPayment: async (userId: number, paymentId: number, paymentPatchDto?: PaymentPatchDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateIndividualPayment', 'userId', userId)
            // verify required parameter 'paymentId' is not null or undefined
            assertParamExists('updateIndividualPayment', 'paymentId', paymentId)
            const localVarPath = `/payments/{userId}/{paymentId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"paymentId"}}`, encodeURIComponent(String(paymentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paymentPatchDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentsControllerApi - functional programming interface
 * @export
 */
export const PaymentsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {InvoicePaymentDto} [invoicePaymentDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInvoicePayment(userId: number, invoicePaymentDto?: InvoicePaymentDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoicePaymentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInvoicePayment(userId, invoicePaymentDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaymentsControllerApi.createInvoicePayment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} invoicePaymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteInvoicePayment(userId: number, invoicePaymentId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteInvoicePayment(userId, invoicePaymentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaymentsControllerApi.deleteInvoicePayment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} invoicePaymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchInvoicePaymentForUserById(userId: number, invoicePaymentId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoicePaymentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchInvoicePaymentForUserById(userId, invoicePaymentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaymentsControllerApi.fetchInvoicePaymentForUserById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {Pageable} pageable 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchInvoicePaymentsForUser(userId: number, pageable: Pageable, search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageInvoicePaymentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchInvoicePaymentsForUser(userId, pageable, search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaymentsControllerApi.fetchInvoicePaymentsForUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {Pageable} pageable 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchPaymentsForUser(userId: number, pageable: Pageable, search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagePaymentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchPaymentsForUser(userId, pageable, search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaymentsControllerApi.fetchPaymentsForUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllAccountCodesForUser(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccountCode>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllAccountCodesForUser(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaymentsControllerApi.getAllAccountCodesForUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvoiceAccountCodesForUser(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccountCode>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvoiceAccountCodesForUser(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaymentsControllerApi.getInvoiceAccountCodesForUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaymentAccountCodesForUser(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccountCode>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaymentAccountCodesForUser(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaymentsControllerApi.getPaymentAccountCodesForUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaymentStatusesForUser(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaymentStatusesForUser(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaymentsControllerApi.getPaymentStatusesForUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} paymentId 
         * @param {PaymentConfirmationDto} [paymentConfirmationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async payPaymentEntry(userId: number, paymentId: number, paymentConfirmationDto?: PaymentConfirmationDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.payPaymentEntry(userId, paymentId, paymentConfirmationDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaymentsControllerApi.payPaymentEntry']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} invoicePaymentId 
         * @param {InvoicePaymentDto} [invoicePaymentDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceInvoicePayment(userId: number, invoicePaymentId: number, invoicePaymentDto?: InvoicePaymentDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoicePaymentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.replaceInvoicePayment(userId, invoicePaymentId, invoicePaymentDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaymentsControllerApi.replaceInvoicePayment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} paymentId 
         * @param {PaymentConfirmationDto} [paymentConfirmationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async undoPaymentEntry(userId: number, paymentId: number, paymentConfirmationDto?: PaymentConfirmationDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.undoPaymentEntry(userId, paymentId, paymentConfirmationDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaymentsControllerApi.undoPaymentEntry']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} paymentId 
         * @param {PaymentPatchDto} [paymentPatchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateIndividualPayment(userId: number, paymentId: number, paymentPatchDto?: PaymentPatchDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Payment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateIndividualPayment(userId, paymentId, paymentPatchDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaymentsControllerApi.updateIndividualPayment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PaymentsControllerApi - factory interface
 * @export
 */
export const PaymentsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentsControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {InvoicePaymentDto} [invoicePaymentDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvoicePayment(userId: number, invoicePaymentDto?: InvoicePaymentDto, options?: any): AxiosPromise<InvoicePaymentDto> {
            return localVarFp.createInvoicePayment(userId, invoicePaymentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} invoicePaymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInvoicePayment(userId: number, invoicePaymentId: number, options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.deleteInvoicePayment(userId, invoicePaymentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} invoicePaymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchInvoicePaymentForUserById(userId: number, invoicePaymentId: number, options?: any): AxiosPromise<InvoicePaymentDto> {
            return localVarFp.fetchInvoicePaymentForUserById(userId, invoicePaymentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {Pageable} pageable 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchInvoicePaymentsForUser(userId: number, pageable: Pageable, search?: string, options?: any): AxiosPromise<PageInvoicePaymentDto> {
            return localVarFp.fetchInvoicePaymentsForUser(userId, pageable, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {Pageable} pageable 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPaymentsForUser(userId: number, pageable: Pageable, search?: string, options?: any): AxiosPromise<PagePaymentDto> {
            return localVarFp.fetchPaymentsForUser(userId, pageable, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAccountCodesForUser(userId: number, options?: any): AxiosPromise<Array<AccountCode>> {
            return localVarFp.getAllAccountCodesForUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoiceAccountCodesForUser(userId: number, options?: any): AxiosPromise<Array<AccountCode>> {
            return localVarFp.getInvoiceAccountCodesForUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentAccountCodesForUser(userId: number, options?: any): AxiosPromise<Array<AccountCode>> {
            return localVarFp.getPaymentAccountCodesForUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentStatusesForUser(userId: number, options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.getPaymentStatusesForUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} paymentId 
         * @param {PaymentConfirmationDto} [paymentConfirmationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payPaymentEntry(userId: number, paymentId: number, paymentConfirmationDto?: PaymentConfirmationDto, options?: any): AxiosPromise<PaymentDto> {
            return localVarFp.payPaymentEntry(userId, paymentId, paymentConfirmationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} invoicePaymentId 
         * @param {InvoicePaymentDto} [invoicePaymentDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceInvoicePayment(userId: number, invoicePaymentId: number, invoicePaymentDto?: InvoicePaymentDto, options?: any): AxiosPromise<InvoicePaymentDto> {
            return localVarFp.replaceInvoicePayment(userId, invoicePaymentId, invoicePaymentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} paymentId 
         * @param {PaymentConfirmationDto} [paymentConfirmationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        undoPaymentEntry(userId: number, paymentId: number, paymentConfirmationDto?: PaymentConfirmationDto, options?: any): AxiosPromise<PaymentDto> {
            return localVarFp.undoPaymentEntry(userId, paymentId, paymentConfirmationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} paymentId 
         * @param {PaymentPatchDto} [paymentPatchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIndividualPayment(userId: number, paymentId: number, paymentPatchDto?: PaymentPatchDto, options?: any): AxiosPromise<Payment> {
            return localVarFp.updateIndividualPayment(userId, paymentId, paymentPatchDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentsControllerApi - object-oriented interface
 * @export
 * @class PaymentsControllerApi
 * @extends {BaseAPI}
 */
export class PaymentsControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} userId 
     * @param {InvoicePaymentDto} [invoicePaymentDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsControllerApi
     */
    public createInvoicePayment(userId: number, invoicePaymentDto?: InvoicePaymentDto, options?: RawAxiosRequestConfig) {
        return PaymentsControllerApiFp(this.configuration).createInvoicePayment(userId, invoicePaymentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {number} invoicePaymentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsControllerApi
     */
    public deleteInvoicePayment(userId: number, invoicePaymentId: number, options?: RawAxiosRequestConfig) {
        return PaymentsControllerApiFp(this.configuration).deleteInvoicePayment(userId, invoicePaymentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {number} invoicePaymentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsControllerApi
     */
    public fetchInvoicePaymentForUserById(userId: number, invoicePaymentId: number, options?: RawAxiosRequestConfig) {
        return PaymentsControllerApiFp(this.configuration).fetchInvoicePaymentForUserById(userId, invoicePaymentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {Pageable} pageable 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsControllerApi
     */
    public fetchInvoicePaymentsForUser(userId: number, pageable: Pageable, search?: string, options?: RawAxiosRequestConfig) {
        return PaymentsControllerApiFp(this.configuration).fetchInvoicePaymentsForUser(userId, pageable, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {Pageable} pageable 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsControllerApi
     */
    public fetchPaymentsForUser(userId: number, pageable: Pageable, search?: string, options?: RawAxiosRequestConfig) {
        return PaymentsControllerApiFp(this.configuration).fetchPaymentsForUser(userId, pageable, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsControllerApi
     */
    public getAllAccountCodesForUser(userId: number, options?: RawAxiosRequestConfig) {
        return PaymentsControllerApiFp(this.configuration).getAllAccountCodesForUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsControllerApi
     */
    public getInvoiceAccountCodesForUser(userId: number, options?: RawAxiosRequestConfig) {
        return PaymentsControllerApiFp(this.configuration).getInvoiceAccountCodesForUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsControllerApi
     */
    public getPaymentAccountCodesForUser(userId: number, options?: RawAxiosRequestConfig) {
        return PaymentsControllerApiFp(this.configuration).getPaymentAccountCodesForUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsControllerApi
     */
    public getPaymentStatusesForUser(userId: number, options?: RawAxiosRequestConfig) {
        return PaymentsControllerApiFp(this.configuration).getPaymentStatusesForUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {number} paymentId 
     * @param {PaymentConfirmationDto} [paymentConfirmationDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsControllerApi
     */
    public payPaymentEntry(userId: number, paymentId: number, paymentConfirmationDto?: PaymentConfirmationDto, options?: RawAxiosRequestConfig) {
        return PaymentsControllerApiFp(this.configuration).payPaymentEntry(userId, paymentId, paymentConfirmationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {number} invoicePaymentId 
     * @param {InvoicePaymentDto} [invoicePaymentDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsControllerApi
     */
    public replaceInvoicePayment(userId: number, invoicePaymentId: number, invoicePaymentDto?: InvoicePaymentDto, options?: RawAxiosRequestConfig) {
        return PaymentsControllerApiFp(this.configuration).replaceInvoicePayment(userId, invoicePaymentId, invoicePaymentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {number} paymentId 
     * @param {PaymentConfirmationDto} [paymentConfirmationDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsControllerApi
     */
    public undoPaymentEntry(userId: number, paymentId: number, paymentConfirmationDto?: PaymentConfirmationDto, options?: RawAxiosRequestConfig) {
        return PaymentsControllerApiFp(this.configuration).undoPaymentEntry(userId, paymentId, paymentConfirmationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {number} paymentId 
     * @param {PaymentPatchDto} [paymentPatchDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsControllerApi
     */
    public updateIndividualPayment(userId: number, paymentId: number, paymentPatchDto?: PaymentPatchDto, options?: RawAxiosRequestConfig) {
        return PaymentsControllerApiFp(this.configuration).updateIndividualPayment(userId, paymentId, paymentPatchDto, options).then((request) => request(this.axios, this.basePath));
    }
}



